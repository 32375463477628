import React, { useState, useEffect } from "react";
import "../App.css";
import { Card, Row, Col, CardBody, CardTitle } from "reactstrap";
import moment from "moment-timezone";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Timing = () => {
	const [timings, setTimings] = useState([]);
	const [city, setCity] = useState(null);
	const [error, setError] = useState(null);
	const [moonPhaseData, setMoonPhaseData] = useState([]);
	const [timezone, setTimezone] = useState(null);

	useEffect(() => {
		const fetchLocation = async () => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					async (position) => {
						const { latitude, longitude } = position.coords;

						try {
							const response = await axios.get(
								`https://api.aladhan.com/v1/calendar/${moment().year()}/${
									moment().month() + 1
								}?latitude=${latitude}&longitude=${longitude}&method=2`,
							);
							const allTimings = response.data.data;
							const timezoneFromApi = response.data.data[0].meta.timezone;
							setTimezone(timezoneFromApi);

							const today = moment().tz(timezoneFromApi);
							const todayIndex = allTimings.findIndex(
								(day) =>
									moment(day.date.gregorian.date, "DD-MM-YYYY").format(
										"YYYY-MM-DD",
									) === today.format("YYYY-MM-DD"),
							);

							if (todayIndex !== -1) {
								const selectedTimings = allTimings.slice(
									todayIndex,
									todayIndex + 3,
								);
								setTimings(selectedTimings);
								fetchMoonPhases(selectedTimings);
							} else {
								setError(
									"Could not find timings for the current day and onwards.",
								);
							}

							try {
								const nominatimResponse = await axios.get(
									`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`,
								);

								if (nominatimResponse && nominatimResponse.data) {
									const address = nominatimResponse.data.address;
									let cityName =
										address.city ||
										address.town ||
										address.village ||
										address.county ||
										address.state ||
										address.country;
									setCity(cityName || "Location not found");
								} else {
									setCity("Location not found");
								}
							} catch (nominatimErr) {
								console.error("Nominatim error:", nominatimErr);
								setCity("Error getting city");
							}
						} catch (err) {
							setError("Failed to fetch prayer times.");
							console.error("Error fetching prayer times:", err);
						}
					},
					(err) => {
						setError("Geolocation failed. Please enable location services.");
						console.error("Geolocation error:", err);
					},
				);
			} else {
				setError("Geolocation is not supported by this browser.");
			}
		};

		fetchLocation();
	}, []);

	const fetchMoonPhases = async (days) => {
		const moonPhaseDataArray = [];
		for (const day of days) {
			try {
				const date = moment(day.date.gregorian.date, "DD-MM-YYYY").format(
					"YYYY-MM-DD",
				);
				const moonPhaseResponse = await axios.get(
					`https://api.apiverve.com/v1/moonphases?date=${date}`,
					{
						headers: {
							"x-api-key": "c871317a-fde9-48d2-a7c4-6a46c9bc1934",
						},
					},
				);

				if (
					moonPhaseResponse &&
					moonPhaseResponse.data &&
					moonPhaseResponse.data.data
				) {
					moonPhaseDataArray.push(moonPhaseResponse.data.data);
				} else {
					console.error("Invalid moon phase data:", moonPhaseResponse.data);
					moonPhaseDataArray.push(null);
				}
			} catch (moonPhaseErr) {
				console.error("Moon phase error:", moonPhaseErr);
				moonPhaseDataArray.push(null);
			}
		}
		setMoonPhaseData(moonPhaseDataArray);
	};

	const sliderSettings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<>
			<div
				className="Card"
				style={{
					backgroundColor: "transparent",
					zIndex: 1,
				}}>
				<div className="m-3">
					<Card className="card animate__animated animate__fadeInUp">
						<CardBody className="p-1">
							<CardTitle tag="h1">Ramadan Timing</CardTitle>
							<Row className="d-flex flex-column justify-content-center align-items-center">
								<Col xs="12">
									{error && <p style={{ color: "red" }}>{error}</p>}
									{timings.length > 0 && (
										<Slider {...sliderSettings}>
											{timings.map((day, index) => (
												<div key={day.date.gregorian.date}>
													<Card className="p-3">
														<p>
															<strong>Date:</strong> {day.date.gregorian.date}
														</p>
														<p>
															<strong>Sehar (Imsak):</strong>{" "}
															{moment
																.tz(day.timings.Imsak, "HH:mm", timezone)
																.format("h:mm A")}
														</p>
														<p>
															<strong>Iftar (Maghrib):</strong>{" "}
															{moment
																.tz(day.timings.Maghrib, "HH:mm", timezone)
																.format("h:mm A")}
														</p>
														{city && <p>Location: {city}</p>}
														{moonPhaseData[index] && (
															<div>
																<div style={{ fontSize: "3em" }}>
																	{moonPhaseData[index].phaseEmoji}
																</div>
																<p>{moonPhaseData[index].phase}</p>
															</div>
														)}
													</Card>
												</div>
											))}
										</Slider>
									)}
									{timings.length === 0 && !error && <p>Loading...</p>}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
};

export default Timing;
