import { NavLink } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "react-tooltip";
import "./App.css";
import {
	Row,
	Col,
	Button,
	Card,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";

import {
	FiHome,
	FiLinkedin,
	FiFacebook,
	FiSettings,
	FiFile,
	FiFolder,
	FiVideo,
	FiPhone,
	FiDisc,
} from "react-icons/fi";

const LogoNav = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

	return isMobile ? (
		<div>
			<Row className="d-flex flex-row justify-content-center align-items-center">
				<Col sm="12">
					<div>
						<CardImg alt="Card image cap" src="./logo.png" width="20" />
					</div>
				</Col>
			</Row>
		</div>
	) : (
		<div className="Header">
			<Row className="d-flex flex-row justify-content-center align-items-center">
				<Col sm="12">
					<div>
						<CardImg
							alt="Card image cap"
							src="./logo.png"
							style={{ width: "30%" }}
						/>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default LogoNav;
